/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { checkHash, isBrowser, scroller } from '@lib/scrollUtils';
import Scripts from './src/Scripts';

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <Scripts />
  </>
);

export const onRouteUpdate = ({ location }, { offset = 0, duration = 1000 }) => {
  let windowHash;

  if (isBrowser) {
    window.gatsby_scroll_offset = offset;
    window.gatsby_scroll_duration = duration;
    windowHash = window.gatsby_scroll_hash;
  }
  setTimeout(() => {
    windowHash ? scroller(windowHash, offset) : checkHash(location, offset);
  }, 800);

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined;
  }
};

export const shouldUpdateScroll = ({ routerProps, prevRouterProps, getSavedScrollPosition }) => {
  const location = routerProps?.location;
  const prevLocation = prevRouterProps?.location;
  const pathname = location?.pathname;
  const prevPathname = prevLocation?.pathname;

  const currentPosition = getSavedScrollPosition(location);

  const delay = pathname.includes('shop') ? 500 : 0;

  if (currentPosition) {
    const { isFilter } = location.state || {};
    if (!isFilter) {
      setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
      }, delay);
    }
  } else {
    window.scrollTo([0, 0]);
  }

  return false;
};

const appHeight = () => {
  if (typeof window === 'undefined') {
    return;
  }
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
typeof window !== 'undefined' && window.addEventListener('resize', appHeight);
