import React from 'react';

const Scripts = () => {
  return (
    <>
      <script
        async
        type="text/plain"
        src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=SicM9w`}
        data-category="analytics"
        data-service="Klaviyo"
      ></script>
      <script
        async
        type="text/plain"
        data-category="analytics"
        data-service="Google"
        src="https://www.googletagmanager.com/gtag/js?id=G-ZGH4QSMRTV"
      ></script>
      <script
        async
        type="text/plain"
        data-category="analytics"
        data-service="Google"
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-ZGH4QSMRTV');
    `,
        }}
      ></script>
    </>
  );
};

export default Scripts;
